import _checkPropTypes from 'prop-types/checkPropTypes';
import React, { createContext, useReducer } from 'react';
import { PropTypes } from 'prop-types';

const MessagingContext = createContext(null);

const initialState = {
  client: null,
  activeConversation: null,
  closedConversations: [],
  isLoading: true,
  errorGettingConvo: false
};

export const MESSAGING_TYPES = {
  SETUP_CLIENT: 'MESSAGING/SETUP_CLIENT',
  SET_LOADING_STATE: 'MESSAGING/SET_LOADING_STATE',
  SET_ERROR_STATE: 'MESSAGING/SET_ERROR_STATE',
  UPDATE_ACTIVE_CONVERSATION: 'MESSAGING/UPDATE_ACTIVE_CONVERSATION',
  SET_JOB_CONVERSATIONS: 'MESSAGING/SET_JOB_CONVERSATIONS'
};

const reducer = (state, action) => {
  switch (action.type) {
    case MESSAGING_TYPES.SETUP_CLIENT:
      return {
        ...state,
        client: action.payload.client
      };
    case MESSAGING_TYPES.SET_LOADING_STATE:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    case MESSAGING_TYPES.SET_ERROR_STATE:
      return {
        ...state,
        errorGettingConvo: action.payload.errorGettingConvo
      };
    case MESSAGING_TYPES.UPDATE_ACTIVE_CONVERSATION:
      return {
        ...state,
        activeConversation: action.payload.conversation
      };
    case MESSAGING_TYPES.SET_JOB_CONVERSATIONS:
      return {
        ...state,
        activeConversation: action.payload.conversation,
        closedConversations: action.payload.closedConversations,
        isLoading: false,
        errorGettingConvo: false
      };
    default:
      return state;
  }
};

const MessagingProvider = ({ children }) => {
  _checkPropTypes(
    {
      children: PropTypes.oneOfType([PropTypes.node, PropTypes.object])
        .isRequired
    },
    {
      children
    },
    'prop',
    'MessagingProvider'
  );

  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <MessagingContext.Provider value={value}>
      {children}
    </MessagingContext.Provider>
  );
};

export { MessagingContext, MessagingProvider };
