import _checkPropTypes from 'prop-types/checkPropTypes';
import React, { createContext, useReducer } from 'react';
import { PropTypes } from 'prop-types';

const UsersContext = createContext(null);

const initialState = {
  loaded: false,
  users: [],
  allCompanies: [],
  loadedGroupData: false,
  allGroups: [],
  loadedActiveUsers: false,
  activeUsers: []
};

export const USERS_TYPES = {
  LOADED: 'USERS/LOADED',
  UPDATE_USERS_DATA: 'USERS/UPDATE_USERS_DATA',
  UPDATE_USERS: 'USERS/UPDATE_USERS',
  UPDATE_ACTIVE_USERS: 'USERS/UPDATE_ACTIVE_USERS',
  GET_GROUPS: 'USERS/GET_GROUPS',
  GET_ACTIVE_USERS: 'USERS/GET_ACTIVE_USERS',
  UPDATE_GROUPS: 'USERS/UPDATE_GROUPS',
  REMOVE_GROUP: 'USERS/REMOVE_GROUP'
};

const reducer = (state, action) => {
  switch (action.type) {
    case USERS_TYPES.LOADED:
      return {
        ...state,
        loaded: true,
        users: [...action.payload.users],
        allCompanies: [...action.payload.companies]
      };
    case USERS_TYPES.UPDATE_USERS_DATA:
      return {
        ...state,
        loaded: true,
        users: [...action.payload.users]
      };
    case USERS_TYPES.UPDATE_USERS:
      return {
        ...state,
        users: action.payload.newUser
          ? [...state.users, action.payload.newUser].sort((a, b) => {
              if (a.user.first_name < b.user.first_name) return -1;
              if (a.user.first_name > b.user.first_name) return 1;
              return 0;
            })
          : state.users.map(user => {
              if (user.id === action.payload.updatedUser.id) {
                return action.payload.updatedUser;
              }
              return user;
            })
      };
    case USERS_TYPES.UPDATE_ACTIVE_USERS:
      return {
        ...state,
        activeUsers: action.payload.newUser
          ? [...state.activeUsers, action.payload.newUser].sort((a, b) => {
              if (a.first_name < b.first_name) return -1;
              if (a.first_name > b.first_name) return 1;
              return 0;
            })
          : state.activeUsers.map(user => {
              if (user.id === action.payload.updatedUser.id) {
                return action.payload.updatedUser;
              }
              return user;
            })
      };
    case USERS_TYPES.GET_GROUPS:
      return {
        ...state,
        loadedGroupData: true,
        allGroups: [...action.payload.allGroups]
      };
    case USERS_TYPES.GET_ACTIVE_USERS:
      return {
        ...state,
        loadedActiveUsers: true,
        activeUsers: [...action.payload.activeUsers]
      };
    case USERS_TYPES.UPDATE_GROUPS:
      const allGroupsArray = action.payload.newGroup
        ? state.allGroups
        : state.allGroups.map(group => {
            if (group.id === action.payload.group.id) {
              return action.payload.group;
            }
            return group;
          });
      if (action.payload.newGroup) {
        allGroupsArray.push(action.payload.group);
      }
      return {
        ...state,
        allGroups: allGroupsArray
      };
    case USERS_TYPES.REMOVE_GROUP:
      return {
        ...state,
        allGroups: state.allGroups.filter(
          group => group.id !== action.payload.group.id
        )
      };
    default:
      return state;
  }
};

const UsersProvider = ({ children }) => {
  _checkPropTypes(
    {
      children: PropTypes.oneOfType([PropTypes.node, PropTypes.object])
        .isRequired
    },
    {
      children
    },
    'prop',
    'UsersProvider'
  );

  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
  );
};

export { UsersContext, UsersProvider };
