import _checkPropTypes from 'prop-types/checkPropTypes';
import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router';

import { getTokens } from '../../shared/utils/helpers';

const RequireUnauthenticated = ({ children }) => {
  _checkPropTypes(
    {
      children: PropTypes.oneOfType([PropTypes.node, PropTypes.object])
        .isRequired
    },
    {
      children
    },
    'prop',
    'RequireUnauthenticated'
  );

  return getTokens().accessToken ? <Navigate to="/jobs" /> : children;
};

export default RequireUnauthenticated;
