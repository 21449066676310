import _checkPropTypes from 'prop-types/checkPropTypes';
import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Backdrop, CircularProgress, Typography } from '@mui/material';

const LoadingContainer = ({ loadingMessage, showLoadingMessage, visible }) => {
  _checkPropTypes(
    {
      loadingMessage: PropTypes.string,
      showLoadingMessage: PropTypes.bool,
      visible: PropTypes.bool
    },
    { loadingMessage, showLoadingMessage, visible },
    'prop',
    'LoadingContainer'
  );

  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: theme => theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(0, 0, 0, 0.7)'
      }}
      open={visible}
    >
      <Stack direction="column" spacing={4} sx={{ alignItems: 'center' }}>
        <CircularProgress color="inherit" />
        {showLoadingMessage && (
          <Typography variant="h5" sx={{ fontWeight: '500' }}>
            {loadingMessage}
          </Typography>
        )}
      </Stack>
    </Backdrop>
  );
};

LoadingContainer.defaultProps = {
  loadingMessage: 'Loading...',
  showLoadingMessage: false,
  visible: false
};

export default LoadingContainer;
