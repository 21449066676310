import _checkPropTypes from 'prop-types/checkPropTypes';
import React, { createContext, useReducer, useEffect, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { getUserNotifications } from '../../../shared/Api';
import { AppContext } from '../../../context/AppContext';

const NotificationsContext = createContext(null);

const initialState = {
  notifications: [],
  hasMoreNotifications: true,
  currentNotificationPage: 1
};

export const NOTIFICATION_ACTION_TYPES = {
  UPDATE_USER_NOTIFICATIONS: 'UPDATE_USER_NOTIFICATIONS',
  UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
  LOAD_MORE_NOTIFICATIONS: 'LOAD_MORE_NOTIFICATIONS',
  HIDE_NOTIFICATION: 'HIDE_NOTIFICATION'
};

const reducer = (state, action) => {
  switch (action.type) {
    case NOTIFICATION_ACTION_TYPES.UPDATE_USER_NOTIFICATIONS:
      // TODO: this should also actually delete the old status_changed notification when a new one comes in
      return {
        ...state,
        notifications: action.payload.notifications
          ? [...action.payload.notifications]
          : action.payload.newNotification.notification_type ===
              'status_changed'
            ? [action.payload.newNotification, ...state.notifications].filter(
                notification => {
                  if (notification.id === action.payload.newNotification.id) {
                    return true;
                  }
                  if (notification.notification_type === 'status_changed') {
                    return (
                      notification.job.id !==
                      action.payload.newNotification.job.id
                    );
                  }
                  return true;
                }
              )
            : [action.payload.newNotification, ...state.notifications],
        hasMoreNotifications: action.payload.hasMoreNotifications
      };
    case NOTIFICATION_ACTION_TYPES.UPDATE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map(notification => {
          if (notification.id === action.payload.updatedNotification.id) {
            return action.payload.updatedNotification;
          }
          return notification;
        })
      };
    case NOTIFICATION_ACTION_TYPES.LOAD_MORE_NOTIFICATIONS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          ...action.payload.notifications
        ],
        hasMoreNotifications: action.payload.hasMoreNotifications,
        currentNotificationPage: action.payload.currentNotificationPage
      };
    case NOTIFICATION_ACTION_TYPES.HIDE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification =>
            notification.id !== action.payload.hiddenNotification.id
        )
      };
    default:
      return state;
  }
};

const NotificationsProvider = ({ children }) => {
  _checkPropTypes(
    {
      children: PropTypes.oneOfType([PropTypes.node, PropTypes.object])
        .isRequired
    },
    {
      children
    },
    'prop',
    'NotificationsProvider'
  );

  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  const { state: appState } = useContext(AppContext);

  useEffect(() => {
    if (appState.accessToken) {
      getUserNotifications(dispatch);
    }
  }, []);

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsContext, NotificationsProvider };
