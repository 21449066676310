import _checkPropTypes from 'prop-types/checkPropTypes';
import React, { createContext, useReducer } from 'react';
import { PropTypes } from 'prop-types';

const AnalyticsContext = createContext(null);

const initialState = {
  userStatsLoaded: false,
  userStats: {
    jobCount: 0,
    revisionTotalCount: 0,
    revisionInvoluntaryCount: 0,
    servicesRequestedCounts: [
      {
        structural_letter: 0,
        structural_stamp: 0,
        electrical_stamp: 0,
        ground_mount_letter: 0,
        non_pv_design: 0,
        cad_packet: 0,
        post_install_letter: 0,
        wall_mount_battery: 0,
        load_calculations: 0,
        shade_report: 0,
        period: 'All Time'
      }
    ],
    avgPendingTime: 0,
    jobActions: {
      cancelled: 0,
      pending_client: 0,
      submitted: 0,
      revision: 0,
      edited: 0,
      pending_approval: 0,
      approved: 0,
      pending_rae: 0,
      created: 0
    }
  },
  groupStatsLoaded: false,
  groupStats: {
    jobCount: 0,
    revisionTotalCount: 0,
    revisionInvoluntaryCount: 0,
    servicesRequestedCounts: [
      {
        structural_letter: 0,
        structural_stamp: 0,
        electrical_stamp: 0,
        ground_mount_letter: 0,
        non_pv_design: 0,
        cad_packet: 0,
        post_install_letter: 0,
        wall_mount_battery: 0,
        load_calculations: 0,
        shade_report: 0,
        period: 'All Time'
      }
    ],
    avgPendingTime: 0,
    jobActions: {
      cancelled: 0,
      pending_client: 0,
      submitted: 0,
      revision: 0,
      edited: 0,
      pending_approval: 0,
      approved: 0,
      pending_rae: 0,
      created: 0
    },
    jobsByUser: []
  },
  companyStatsLoaded: false,
  companyStats: {
    jobCount: 0,
    revisionTotalCount: 0,
    revisionInvoluntaryCount: 0,
    reinforcementCount: 0,
    avgTurnaroundTimes: {
      design: 0,
      engineering: 0,
      all: 0
    },
    revisionReasons: {},
    servicesRequestedCounts: [
      {
        structural_letter: 0,
        structural_stamp: 0,
        electrical_stamp: 0,
        ground_mount_letter: 0,
        non_pv_design: 0,
        cad_packet: 0,
        post_install_letter: 0,
        wall_mount_battery: 0,
        load_calculations: 0,
        shade_report: 0,
        period: 'All Time'
      }
    ],
    jobsCreatedCounts: {
      createdCount: 0,
      period: 'All Time'
    },
    avgRevisionCount: 0
  }
};

export const ANALYTICS_TYPES = {
  UPDATE_MONTH_STATS: 'ANALYTICS/UPDATE_MONTH_STATS',
  UPDATE_USER_STATS: 'ANALYTICS/UPDATE_USER_STATS',
  UPDATE_GROUP_STATS: 'ANALYTICS/UPDATE_GROUP_STATS',
  UPDATE_COMPANY_STATS: 'ANALYTICS/UPDATE_COMPANY_STATS',
  ADJUST_INVOLUNTARY_COUNT: 'ANALYTICS/ADJUST_INVOLUNTARY_COUNT'
};

const reducer = (state, action) => {
  switch (action.type) {
    case ANALYTICS_TYPES.UPDATE_USER_STATS:
      return {
        ...state,
        userStatsLoaded: true,
        userStats: { ...action.payload }
      };
    case ANALYTICS_TYPES.UPDATE_GROUP_STATS:
      return {
        ...state,
        groupStatsLoaded: true,
        groupStats: { ...action.payload }
      };
    case ANALYTICS_TYPES.UPDATE_COMPANY_STATS:
      return {
        ...state,
        companyStatsLoaded: true,
        companyStats: { ...action.payload }
      };
    case ANALYTICS_TYPES.ADJUST_INVOLUNTARY_COUNT:
      const newState = { ...state };

      // adjust the total count
      if (action.payload.newIsInvoluntary) {
        newState[action.payload.tabKey].revisionInvoluntaryCount =
          state[action.payload.tabKey].revisionInvoluntaryCount + 1;
      } else {
        newState[action.payload.tabKey].revisionInvoluntaryCount =
          state[action.payload.tabKey].revisionInvoluntaryCount - 1;
      }

      // adjust the count for that user in the by user chart
      if (action.payload.tabKey === 'groupStats') {
        newState.groupStats.jobsByUser = state.groupStats.jobsByUser.map(
          userData => {
            if (userData.user === action.payload.approver) {
              const newUserData = {
                ...userData,
                revised: action.payload.newIsInvoluntary
                  ? userData.revised + 1
                  : userData.revised - 1
              };
              return newUserData;
            }
            return userData;
          }
        );
      }
      return newState;
    default:
      return state;
  }
};

const AnalyticsProvider = ({ children }) => {
  _checkPropTypes(
    {
      children: PropTypes.oneOfType([PropTypes.node, PropTypes.object])
    },
    {
      children
    },
    'prop',
    'AnalyticsProvider'
  );

  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export { AnalyticsContext, AnalyticsProvider };
