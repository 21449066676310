import _checkPropTypes from 'prop-types/checkPropTypes';
import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router';

import { getTokens } from '../../shared/utils/helpers';

const RequireAuthenticated = ({ children }) => {
  _checkPropTypes(
    {
      children: PropTypes.oneOfType([PropTypes.node, PropTypes.object])
        .isRequired
    },
    {
      children
    },
    'prop',
    'RequireAuthenticated'
  );

  const location = useLocation();

  return getTokens().accessToken ? (
    children
  ) : (
    <Navigate
      to="/login"
      state={{
        fromAuthRoute: true,
        from: location.pathname
      }}
    />
  );
};

export default RequireAuthenticated;
